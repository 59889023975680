import React from 'react'
import PropTypes from 'prop-types'

import schedule from '../../../assets/businessHoursSchedule.json'

import styles from './BusinessHours.module.scss'

const Day = ({ dayIndex, i18n, todayIndex }) => {
  const { day, open, closed } = schedule[dayIndex]

  return (
    <tr className={dayIndex === todayIndex ? styles.today : null}>
      <td>{i18n.days[day]}</td>

      {open && closed && (
        <td>
          {[
            open.length < 5 ? `0${open}` : open,
            '-',
            closed.length < 5 ? `0${closed}` : closed,
          ].join(' ')}
        </td>
      )}

      {(!open || !closed) && <td>{i18n.closed}</td>}
    </tr>
  )
}

Day.propTypes = {
  dayIndex: PropTypes.number.isRequired,
  i18n: PropTypes.object.isRequired,
  todayIndex: PropTypes.number,
}

export default Day
