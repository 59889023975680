import React from 'react'
import PropTypes from 'prop-types'

const TheTeam = ({ i18n }) => (
  <section className="t-justify" id={i18n.index.theTeam.anchor}>
    <div className="wrapper-s">
      <h2 className="t-heading-xxs t-muted">{i18n.index.theTeam.title}</h2>
      <span className="t-heading-sub">{i18n.index.theTeam.subtitle}</span>
      <p>{i18n.index.theTeam.p1}</p>
      <p>{i18n.index.theTeam.p2}</p>
    </div>
  </section>
)

TheTeam.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default TheTeam
