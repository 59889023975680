import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Brands from './Brands'

import styles from './Hero.module.scss'

const query = graphql`
  query {
    storefront: file(relativePath: { eq: "assets/storefront.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, maxHeight: 520) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = ({ i18n }) => (
  <section className={styles.hero}>
    <div className="wrapper-l">
      <StaticQuery
        query={query}
        render={(data) => (
          <Img
            alt={i18n.index.storefront}
            critical
            fluid={data.storefront.childImageSharp.fluid}
          />
        )}
      />

      <Brands />
    </div>
  </section>
)

Hero.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Hero
