import React from 'react'
import PropTypes from 'prop-types'

import FormContext from './FormContext'
import GlobalContext from '../../utils/globalContext'

class Form extends React.Component {
  static contextType = GlobalContext

  state = {
    fieldsTouched: {},
    fieldsValues: this.props.initialValues,
  }

  _setData = (target, name, value) => {
    const data = this.state[target]

    data[name] = value

    this.setState({ [target]: data })
  }

  _handleSubmit = (e) => {
    e.persist()
    e.preventDefault()

    this.setState({ formTouched: true })

    const { ongewenst, ongewenstalt } = e.target.elements

    if (!ongewenst.value && !ongewenstalt.value && e.target.checkValidity()) {
      this.props
        .onSubmit({
          values: this.state.fieldsValues,
          language: this.context.language,
        })
        .then(() => {
          this.setState({
            fieldsTouched: {},
            fieldsValues: {},
            formTouched: false,
          })
        })
        .catch(() => {
          return false
        })
    }
  }

  render() {
    const formContext = {
      fieldsTouched: this.state.fieldsTouched,
      fieldsValues: this.state.fieldsValues,
      formTouched: this.state.formTouched,
      setData: this._setData,
    }

    return (
      <FormContext.Provider value={formContext}>
        <form onSubmit={this._handleSubmit} noValidate>
          <label className="ongewenst">
            Dit veld leeglaten / Leave this field empty / Dieses Feld leer
            lassen
            <input type="text" name="ongewenst" tabIndex="-1" />
          </label>
          <input type="hidden" name="ongewenstalt" />
          {this.props.children}
        </form>
      </FormContext.Provider>
    )
  }
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

Form.defaultProps = {
  initialValues: {},
}

export default Form
