import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import Button from '../../Button'
import { Form, Input, Textarea } from '../../Form'

import styles from './Contact.module.scss'

class ContactForm extends React.Component {
  state = {
    error: null,
    message: null,
  }

  _handleSubmit = async (formData) => {
    const { i18n } = this.props

    try {
      await this.setState({
        error: null,
        message: null,
      })

      await axios.post(
        'https://staticmailer.pexel.nl/30oug3HCRyRo',
        formData.values
      )

      await this.setState({
        message: i18n.index.contact.sendSuccess,
      })
    } catch (error) {
      await this.setState({
        error: i18n.index.contact.sendError,
      })

      throw new Error(error.message)
    }
  }

  render() {
    const { i18n } = this.props
    const { error, message } = this.state

    return (
      <div className={styles.contactForm}>
        {error && <div className={styles.feedbackError}>{error}</div>}
        {message && <div className={styles.feedbackMessage}>{message}</div>}

        <Form onSubmit={this._handleSubmit}>
          <Input
            name="name"
            type="text"
            label={i18n.index.contact.labelName}
            required
          />

          <Input
            name="email"
            type="email"
            label={i18n.index.contact.labelEmail}
            required
          />

          <Textarea
            name="message"
            label={i18n.index.contact.labelMessage}
            required
          />

          <Button type="submit" color="confirm">
            {i18n.index.contact.labelSend}
          </Button>
        </Form>
      </div>
    )
  }
}

ContactForm.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default ContactForm
