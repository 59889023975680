import React from 'react'
import PropTypes from 'prop-types'

import ContactForm from './ContactForm'
import Info from './Info'
import Map from './Map'

import styles from './Contact.module.scss'

const Contact = ({ i18n }) => (
  <>
    <section className="t-center" id={i18n.index.contact.anchor}>
      <div className="wrapper-l">
        <div className={styles.content}>
          <Info i18n={i18n} />
          <Map />
        </div>
      </div>
    </section>

    <section className="t-center">
      <div className="wrapper-s">
        <ContactForm i18n={i18n} />
      </div>
    </section>
  </>
)

Contact.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Contact
