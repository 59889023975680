import React from 'react'

import brands from '../../../assets/brands/list'
import shuffleArray from '../../../utils/shuffleArray'

import styles from './Hero.module.scss'

class Brands extends React.Component {
  state = {
    brandsArray: brands,
    loading: true,
  }

  componentDidMount = () => {
    this._shuffleBrands()
  }

  _shuffleBrands = () => {
    const shuffledBrandsArray = shuffleArray(this.state.brandsArray)
    this.setState({ brandsArray: shuffledBrandsArray, loading: false })

    setTimeout(() => {
      this._shuffleBrands()
    }, 10000)
  }

  render() {
    const { brandsArray, loading } = this.state

    const classNames = {
      brands: [styles.brands, loading ? styles.loading : null].join(' '),
    }

    return (
      <div className={classNames.brands}>
        {brandsArray.map((item) => (
          <div key={item.name} className={styles.logoBox}>
            <img src={item.image} alt={item.name} />
          </div>
        ))}
      </div>
    )
  }
}

export default Brands
