import React from 'react'

import styles from './Contact.module.scss'

const Map = () => (
  <iframe
    className={styles.map}
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d617.8073962611604!2d5.880715999999998!3d51.728835999999916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c70e40fc1f6751%3A0xf262c72aea513b75!2sPer+Tutti!5e0!3m2!1sen!2s!4v1409765800910"
    title="map"
  />
)

export default Map
