import React from 'react'
import PropTypes from 'prop-types'

import img_signOpen from './signOpen.svg'
import img_signClosed from './signClosed.svg'

import styles from './BusinessHours.module.scss'

const Sign = ({ i18n, storeOpen }) => {
  const classNames = {
    sign: [styles.sign, storeOpen ? styles.open : styles.closed].join(' '),
  }

  return (
    <div className={classNames.sign}>
      <img
        className={styles.signImg}
        src={storeOpen ? img_signOpen : img_signClosed}
        alt={i18n.index.businessHours[storeOpen ? 'signOpen' : 'signClosed']}
      />
    </div>
  )
}

Sign.propTypes = {
  i18n: PropTypes.object.isRequired,
  storeOpen: PropTypes.bool,
}

export default Sign
