import React from 'react'
import PropTypes from 'prop-types'

const AboutUs = ({ i18n }) => (
  <section className="t-justifyCenter" id={i18n.index.aboutUs.anchor}>
    <div className="wrapper-s">
      <h2 className="t-heading-xxs t-muted">{i18n.index.aboutUs.title}</h2>
      <span className="t-heading-sub">{i18n.index.aboutUs.subtitle}</span>
      <p>{i18n.index.aboutUs.p1}</p>
      <p>{i18n.index.aboutUs.p2}</p>
    </div>
  </section>
)

AboutUs.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default AboutUs
