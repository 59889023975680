import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../Button'

import styles from './Newsletter.module.scss'

const Newsletter = ({ i18n }) => (
  <section className="t-center" id={i18n.index.newsletter.anchor}>
    <div className="wrapper-s">
      <h2 className="t-heading-xxs t-muted">{i18n.index.newsletter.title}</h2>
      <span className="t-heading-sub">{i18n.index.newsletter.subtitle}</span>

      <br />

      <form
        action="https://pertuttimode.us16.list-manage.com/subscribe/post?u=6f53194edb98ec7c2467930f8&amp;id=d2428e75f0"
        method="post"
        target="_blank"
      >
        <div className={styles.field}>
          <input
            className={styles.controller}
            name="EMAIL"
            onBlur={(e) => e.target.classList.add(styles.touched)}
            placeholder={i18n.index.newsletter.placeholderEmail}
            required
            type="email"
          />

          <div
            aria-hidden="true"
            style={{ position: 'absolute', left: '-5000px' }}
          >
            <input
              name="b_6f53194edb98ec7c2467930f8_d2428e75f0"
              tabIndex="-1"
              type="text"
            />
          </div>

          <Button color="text" inline size="small" type="submit">
            {i18n.index.newsletter.submit}
          </Button>
        </div>
      </form>
    </div>
  </section>
)

Newsletter.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Newsletter
