import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../Icon'

import styles from './Contact.module.scss'

const Info = ({ i18n }) => (
  <div className={styles.info}>
    <p>{i18n.misc.address}</p>
    <br />
    <p>
      <a className={styles.phoneLink} href={`tel:${i18n.misc.phoneHtml}`}>
        {i18n.misc.phoneHuman}
      </a>
      <br />
      <a href={`mailto:${i18n.misc.email}`}>{i18n.misc.email}</a>
    </p>
    <br />
    <p>
      {i18n.social.map((item) => (
        <a
          alt={item.title}
          className={styles.socialLink}
          href={item.url}
          key={item.title}
          name={item.title}
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <Icon name={item.icon} size={32} />
        </a>
      ))}
    </p>
  </div>
)

Info.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Info
