import React from 'react'

import AboutUs from '../components/pageIndex/AboutUs'
import BusinessHours from '../components/pageIndex/BusinessHours'
import Contact from '../components/pageIndex/Contact'
import GlobalContext from '../utils/globalContext'
import Hero from '../components/pageIndex/Hero'
import Inspiration from '../components/pageIndex/Inspiration'
import Newsletter from '../components/pageIndex/Newsletter'
import Root from '../layouts/Root'
import TheTeam from '../components/pageIndex/TheTeam'

const Index = () => (
  <Root>
    <GlobalContext.Consumer>
      {({ i18n, storeOpen }) => (
        <main>
          <Hero i18n={i18n} />
          <AboutUs i18n={i18n} />
          {/* <Inspiration i18n={i18n} /> */}
          <TheTeam i18n={i18n} />
          <BusinessHours i18n={i18n} storeOpen={storeOpen} />
          <Newsletter i18n={i18n} />
          <Contact i18n={i18n} />
        </main>
      )}
    </GlobalContext.Consumer>
  </Root>
)

export default Index
