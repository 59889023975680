const list = [
  {
    name: 'ALIX',
    image: require('./alix.png'),
  },
  {
    name: 'Cambio',
    image: require('./cambio.png'),
  },
  {
    name: 'Closed',
    image: require('./closed.png'),
  },
  {
    name: 'Goosecraft',
    image: require('./goosecraft.png'),
  },
  {
    name: 'Japan TKY',
    image: require('./japantky.png'),
  },
  {
    name: 'Manila Grace',
    image: require('./manilagrace.png'),
  },
  {
    name: "Marc O'Polo",
    image: require('./marcopolo.png'),
  },
  {
    name: 'Mos Mosh',
    image: require('./mosmosh.png'),
  },
  {
    name: 'P448',
    image: require('./p448.png'),
  },
  {
    name: 'Penn & Ink',
    image: require('./pennandink.png'),
  },
  {
    name: 'Ruby Tuesday',
    image: require('./rubytuesday.png'),
  },
  {
    name: 'TRVL DRSS',
    image: require('./trvldrss.png'),
  },
  {
    name: 'No Man\'s Land',
    image: require('./nomansland.png'),
  },
  {
    name: 'Club l\'Avenir',
    image: require('./clublavenir.png'),
  },
  {
    name: 'Krakatau',
    image: require('./krakatau.png'),
  },
  {
    name: 'Zenggi',
    image: require('./zenggi.png'),
  },
]

export default list
