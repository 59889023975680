import React from 'react'
import PropTypes from 'prop-types'

import Overview from './Overview'
import Sign from './Sign'

import styles from './BusinessHours.module.scss'

const BusinessHours = ({ i18n, storeOpen }) => (
  <section id={i18n.index.businessHours.anchor}>
    <div className="wrapper-l">
      <div className={styles.businessHours}>
        <Overview i18n={i18n} />
        <Sign i18n={i18n} storeOpen={storeOpen} />
      </div>
    </div>
  </section>
)

BusinessHours.propTypes = {
  i18n: PropTypes.object.isRequired,
  storeOpen: PropTypes.bool,
}

export default BusinessHours
