import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import Icon from '../../Icon'

import styles from './Inspiration.module.scss'

class Inspiration extends React.Component {
  state = {
    instagramFeed: [],
    loadAmount: 0,
  }

  componentDidMount = () => {
    this._loadAmount()
    window.addEventListener('resize', this._loadAmount)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this._loadAmount)
  }

  _loadAmount = () => {
    let newLoadAmount

    if (window) {
      const phone = window.matchMedia(`(max-width: 479px)`)
      const phablet = window.matchMedia(`(min-width: 480px) and (max-width: 799px)`)
      const tablet = window.matchMedia(`(min-width: 800px) and (max-width: 1279px)`)
      const laptop = window.matchMedia(`(min-width: 1280px)`)

      if (phone.matches) newLoadAmount = 4
      if (phablet.matches) newLoadAmount = 6
      if (tablet.matches) newLoadAmount = 8
      if (laptop.matches) newLoadAmount = 10
    } else {
      newLoadAmount = 4
    }
    this.setState((prevState) => {
      if (prevState.loadAmount !== newLoadAmount) {
        if (prevState.loadAmount < newLoadAmount) {
          this._loadFeed(newLoadAmount)
        }

        return {
          loadAmount: newLoadAmount,
        }
      }
    })
  }

  _loadFeed = async (loadAmount) => {
    console.log('calling ', loadAmount)
    const data = await axios.get(`https://www.instagram.com/pertutti_mode/`)
    const parsedData = data.data
      .split("window._sharedData = ")[1]
      .split("</script>")[0]

    const jsonData = JSON.parse(parsedData.substr(0, parsedData.length - 1))
    const edges = jsonData.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges

    const url = (node) => {
      return `https://www.instagram.com/p/${node.shortcode}`
    }

    const src = (node) => {
      const lastImg = node.thumbnail_resources[node.thumbnail_resources.length - 1]

      return {
        height: `${lastImg.config_height}px`,
        width: `${lastImg.config_width}px`,
        src: lastImg.src,
      }
    }

    const srcset = (node) => node.thumbnail_resources.map((item, index, array) => ({
      height: `${item.config_height}px`,
      width: `${item.config_width}px`,
      media: [
        array[index - 1] ? `(min-width: ${item.config_width}px)` : null,
        array[index + 1] ? `(max-width: ${array[index + 1].config_width - 1}px)` : null
      ].filter((x) => x).join(' and '),
      src: item.src,
    })
    )

    const alt = (node) => {
      if (
        node.edge_media_to_caption.edges[0] &&
        node.edge_media_to_caption.edges[0].node
      ) {
        return node.edge_media_to_caption.edges[0].node.text
      }
      if (node.accessibility_caption) {
        return node.accessibility_caption
      }
      return ""
    }

    const gallery = edges
      .filter((edge) => edge.node.__typename !== 'GraphVideo')
      .slice(0, loadAmount || this.state.loadAmount)
      .map((edge) => ({
        alt: alt(edge.node),
        url: url(edge.node),
        src: src(edge.node),
        srcset: srcset(edge.node),
      }))

    this.setState({ instagramFeed: gallery })
  }

  render() {
    const { i18n } = this.props
    const { instagramFeed } = this.state

    return (
      <section className="t-center" id={i18n.index.inspiration.anchor}>
        <div className="wrapper-l">
          <div className={styles.instagram}>
            {instagramFeed.slice(0, this.state.loadAmount).map((item) => (
              <a
                key={item.url}
                className={styles.image}
                href={item.url}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <div className={styles.overlay}>
                  <Icon name="socialInstagram" size="40" />
                  <span>{i18n.index.inspiration.viewOnInstagram}</span>
                </div>


                <picture>
                  {item.srcset.map((src) => <source key={src.src} type="image/jpg" srcSet={src.src} media={src.media} />)}
                  <img src={item.src.src} alt={item.alt} width={item.src.width} height={item.src.height} />
                </picture>
              </a>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

Inspiration.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Inspiration
