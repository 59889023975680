import React from 'react'
import PropTypes from 'prop-types'

import Day from './Day'

import styles from './BusinessHours.module.scss'

class Overview extends React.Component {
  state = {
    todayIndex: undefined,
  }

  componentDidMount = () => {
    this.setState({
      todayIndex: new Date().getDay(),
    })
  }

  render() {
    const { i18n } = this.props
    const { todayIndex } = this.state

    const { shoppingSundays } = i18n.index.businessHours
    const shoppingSundaysTextSplit = shoppingSundays.text.split('[url]')

    return (
      <div className={styles.overview}>
        <h2 className="t-heading-s">{i18n.index.businessHours.title}</h2>

        <table className={['table', styles.table].join(' ')}>
          <tbody>
            <Day
              dayIndex={1}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />

            <Day
              dayIndex={2}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />

            <Day
              dayIndex={3}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />

            <Day
              dayIndex={4}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />

            <Day
              dayIndex={5}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />

            <Day
              dayIndex={6}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />

            <Day
              dayIndex={0}
              i18n={i18n.index.businessHours}
              todayIndex={todayIndex}
            />
          </tbody>
        </table>

        <p className="t-small t-muted">
          {shoppingSundaysTextSplit[0]}
          <a
            href={shoppingSundays.url}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {shoppingSundays.urlText}
          </a>
          {shoppingSundaysTextSplit[1]}
        </p>
      </div>
    )
  }
}

Overview.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Overview
